import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Grid,
  Box,
  Typography,
  Alert,
  Skeleton,
} from "@mui/material";
import { WhiteSecurityIcon } from "../common/constants";
import { useTranslation } from "react-i18next";
import {
  selectCurrencySymbol,
  setLatestPaymentMethod,
} from "../redux/slices/paymentMethodsSlice";
import CreateVccCard from "../components/VccCard/CreateVccCard";
import { useAPI, Version } from "../api/api";
import paymentService from "../api/paymentService";
import {
  PaymentMethodsResponse,
  PaymentType,
  VirtualCardResponse,
} from "../types/paymentTypes";
import { setCardData } from "../redux/slices/vccSlice";
import { CardData, TransformedVccCardType, VccCardType } from "../types/common";
import useDecrypt from "../common/useDecrypt";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { getVirtualCard } from "../api/paymentMethodsService";
import { showError, showToast } from "../redux/slices/toastSlice";
import { Payment } from "../types/stipendTypes";
import { paymentsService } from "../api/stipendsServices";
import { useErrorBoundary } from "react-error-boundary";
import { AxiosError } from "axios";
import { ErrorType, getErrorDetails } from "../common/CustomErrorBoundary";
import StorageManager from "../services/storage";

const PaymentMethods: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const failedPayment = searchParams.get("failedPayment") === "true";

  const paymentMethodsSourceData = useSelector(
    (state: RootState) => state.paymentMethods.data
  ) as PaymentMethodsResponse;

  const paymentMethodsData =
    paymentMethodsSourceData.available_payment_methods ?? [];

  const isVCC2PaymentMethodAvailable = paymentMethodsData.some(
    (item) => item.payment_type === PaymentType.VirtualCardV2
  );
  const {
    data: vcc1Data,
    loading: isVCC1Loading,
    error: vcc1Error,
  } = useAPI<VirtualCardResponse[]>(getVirtualCard("v1"));

  const {
    data: vcc2Data,
    loading: isVCC2Loading,
    error: vcc2Error,
  } = useAPI<VirtualCardResponse[]>(getVirtualCard("v2"));

  const {
    data: stipends,
    loading: loadingStipends,
    error: stipendsError,
  } = useAPI<Payment[]>(paymentsService(), []);

  const latestPaymentMethod = useSelector(
    (state: RootState) => state.paymentMethods.data?.latest_payment_account
  ) as any;
  const vcc1 = useSelector<RootState, TransformedVccCardType | null>(
    (state) => state.vcc.vcc1Data
  );
  const vcc2 = useSelector<RootState, TransformedVccCardType | null>(
    (state) => state.vcc.vcc2Data
  );

  const { decryptedId: decryptedVcc1Value, decryptionLoading: decryptingVcc1 } =
    useDecrypt(vcc1?.target_account_identifier, [vcc1]);
  const { decryptedId: decryptedVcc2Value, decryptionLoading: decryptingVcc2 } =
    useDecrypt(vcc2?.target_account_identifier, [vcc2]);

  const currencySymbol = useSelector((state: RootState) =>
    selectCurrencySymbol(state)
  );

  const sumAmountFromStipends = React.useMemo(() => {
    return stipends?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.amount;
    }, 0);
  }, [stipends?.length]);

  const [isSettingVCAsPaymentAccount, settingVCAsPaymentAccount] =
    useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState<string>("");
  const [selectedMethod, setSelectedMethod] = useState<any>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    if (!vcc1Error) {
      return;
    }
    // if 404, the user doesn't have a vcc
    if (vcc1Error.response?.status == 404) {
      return;
    }
    showBoundary(vcc1Error);
  }, [vcc1Error]);

  useEffect(() => {
    if (!vcc2Error) {
      return;
    }
    // if 404, the user doesn't have a vcc
    if (vcc2Error.response?.status == 404) {
      return;
    }
    showBoundary(vcc2Error);
  }, [vcc2Error]);

  useEffect(() => {
    if (vcc1Data && vcc1Data[0]) {
      const cardData: CardData = {
        target_account_id: vcc1Data[0].id,
        card_number: vcc1Data[0].card_number,
        balance: vcc1Data[0].balance,
        type: PaymentType.VirtualCardV1,
      };
      dispatch(setCardData(cardData));
    }
  }, [vcc1Data]);

  useEffect(() => {
    if (vcc2Data && vcc2Data[0]) {
      const cardData: CardData = {
        target_account_id: vcc2Data[0].id,
        card_number: vcc2Data[0].card_number,
        balance: vcc2Data[0].balance,
        type: PaymentType.VirtualCardV2,
      };
      dispatch(setCardData(cardData));
    }
  }, [vcc2Data]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentType((event.target as HTMLInputElement).value);
    const method = paymentMethodsData.find(
      (method: any) =>
        method.payment_type === (event.target as HTMLInputElement).value
    );
    setSelectedMethod(method);
  };

  async function setVCCAsLatestPayment(version: Version) {
    try {
      const vccPaymentCreationData = await paymentService.createVccPayment(
        version
      );
      dispatch(setLatestPaymentMethod(vccPaymentCreationData));
      dispatch(showToast(t("paymentMethod_updated")));
      settingVCAsPaymentAccount(false);
      navigate("/payments");
    } catch (err) {
      const errorDetails = getErrorDetails(err as AxiosError, t);
      if (errorDetails && errorDetails.type !== ErrorType.TERMS_UPDATED) {
        dispatch(
          showError({
            title: errorDetails.props.title,
            message: errorDetails.props.message,
          })
        );
      } else {
        showBoundary(err);
      }
      settingVCAsPaymentAccount(false);
    }
  }

  const handleContinueClick = async () => {
    if (selectedMethod.payment_type === PaymentType.VirtualCardV1 && vcc1) {
      if (
        latestPaymentMethod.target_account_type !== PaymentType.VirtualCardV1
      ) {
        const { balance, ...vccReqWithoutBalance } = vcc1;
        dispatch(setLatestPaymentMethod(vccReqWithoutBalance));
      }
      settingVCAsPaymentAccount(true);
      await setVCCAsLatestPayment("v1");
      return;
    }

    if (selectedMethod.payment_type === PaymentType.VirtualCardV2 && vcc2) {
      if (
        latestPaymentMethod.target_account_type !== PaymentType.VirtualCardV2
      ) {
        const { balance, ...vccReqWithoutBalance } = vcc2;
        dispatch(setLatestPaymentMethod(vccReqWithoutBalance));
      }
      settingVCAsPaymentAccount(true);
      await setVCCAsLatestPayment("v2");
      return;
    }

    if (selectedMethod) {
      StorageManager.removeFormData();
      navigate(`/payment-methods/${selectedMethod.payment_type}`);
    }
  };

  function vccLabelSkeleton() {
    return (
      <span>
        <Skeleton
          variant="rounded"
          height={20}
          width={35}
          style={{ marginLeft: "10px" }}
        />
      </span>
    );
  }

  function paymentMethodLabel(method: any): React.ReactNode {
    if (method.payment_type === PaymentType.VirtualCardV1 && vcc1) {
      return (
        <>
          {method.title + " "}
          {decryptedVcc1Value === null && vcc1?.target_account_id
            ? vccLabelSkeleton()
            : decryptedVcc1Value}
        </>
      );
    }

    if (method.payment_type === PaymentType.VirtualCardV2 && vcc2) {
      return (
        <>
          {method.title + " "}
          {decryptedVcc2Value === null && vcc2?.target_account_id
            ? vccLabelSkeleton()
            : decryptedVcc2Value}

          {/* <Chip sx={{ ml: 0.8 }} label="New" color="success" size="small" /> */}
        </>
      );
    }

    return method.title;
  }

  return (
    <>
      {!latestPaymentMethod &&
        (loadingStipends ? (
          <Skeleton
            variant="rounded"
            height={48}
            style={{ marginBottom: 10 }}
          />
        ) : (
          <Alert
            severity="info"
            sx={{
              backgroundColor: "#0588D1",
              marginBottom: "10px",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {sumAmountFromStipends === 0
              ? t("paymentMethod_choose")
              : t("paymentMethod_amountWaiting", {
                  currency: currencySymbol,
                  amount: sumAmountFromStipends?.toFixed(2),
                })}
          </Alert>
        ))}

      {failedPayment && (
        <Alert
          severity="info"
          sx={{
            backgroundColor: "#0588D1",
            marginBottom: "10px",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {t("paymentMethod_retry")}
        </Alert>
      )}

      {isVCC1Loading || isVCC2Loading || loadingStipends ? (
        <Skeleton
          variant="rounded"
          height={142}
          style={{
            marginBottom: "16px",
          }}
        />
      ) : (
        <Grid
          container
          direction="column"
          borderRadius="4px"
          border="1px solid grey"
          p={2}
          mb={2}
        >
          <Grid textAlign="left" item>
            <FormControl component="fieldset">
              <RadioGroup
                data-test-id="payment-type"
                name="payment-type"
                value={selectedPaymentType}
                onChange={handleChange}
              >
                {paymentMethodsData.map((method: any, index: number) => {
                  if (
                    (method.payment_type === PaymentType.VirtualCardV1 &&
                      !vcc1) ||
                    (method.payment_type === PaymentType.VirtualCardV1 &&
                      vcc1 &&
                      vcc2) ||
                    (method.payment_type === PaymentType.VirtualCardV2 && !vcc2)
                  )
                    return null;
                  return (
                    <FormControlLabel
                      sx={{ fontSize: "0.875rem" }}
                      key={index}
                      value={method.payment_type}
                      control={<Radio />}
                      label={
                        <Typography
                          data-test-id="payment-method-title"
                          fontSize="0.875rem"
                          style={{ display: "flex" }}
                        >
                          {paymentMethodLabel(method)}
                        </Typography>
                      }
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item width="100%">
            <Box mt={3}>
              <LoadingButton
                data-test-id="payment-methods-button"
                fullWidth
                size="large"
                variant="contained"
                disabled={!selectedMethod}
                onClick={handleContinueClick}
                loading={isSettingVCAsPaymentAccount}
                loadingPosition="start"
                startIcon={<WhiteSecurityIcon />}
              >
                {t("continue")}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      )}
      {/* {!isVCC2Loading && isVCC2PaymentMethodAvailable && !vcc2 && (
        <CreateVccCard />
      )} */}
    </>
  );
};

export default PaymentMethods;
